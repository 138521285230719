*{
    margin:0px;
    padding:0px;
    overflow: hidden;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
/* ***** base page ***** */

.base{
    background-color: #C3E6FE;
    height: 100vh;
    width: 100%;
    padding: 10px 20px;
}
.base div button{
    text-transform: capitalize;
    background-color:#036EB6; 
    color:white;
    padding:10px 30px;
}
.base div button:hover{
    background-color: #154360;
}
.basea{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}
.baseb{
    background-color: transparent;
}
.baseb div{
    font-size: 4em;
    font-weight: 300;
    color: #5C666D;
}
.basec{
    background-color:transparent;
    color:#036EB6;
    position: absolute;
    bottom:10px;
    text-align: center;
    width: calc(100% - 40px);
}
.basec h3{
    font-size: 2em;
    font-weight: 300;
}
/* form style */

.box{
    position: absolute;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.6);
    display: none;
}


/*  **Page not found 404** */

.page404{
    width: 100%;
    height: 100vh;
    background-color: #F9B354;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page404 div{
    background-color: #F8F9F9;
    text-align: center;
    padding:30px 50px;
    border-radius: 15px;
    box-shadow: 5px 5px 2px white , 
    -5px -5px 2px white,
    5px -5px 2px white,
    -5px 5px 2px white;
    color:red;
}
.page404 div h3{
    font-size: 1.2em;
    font-weight: 300;
}
.page404 div button{
    background-color: black;
    color:white;
    padding:10px 20px;
    margin-top: 30px;
    text-transform: capitalize;
    font-size: 1.2em;
    font-weight: 300;
}
.page404 div button:hover{
    background-color: #036EB6;
}

/*** display data */
.data {
    background-color: #F2F3F4;
    margin: 70px;
    border-radius: 20px;
    padding:20px;
    height: calc(100vh - 140px);
    position: relative;
    overflow:auto;
}


button.close{
    background-color:black;
    color:white; 
    padding:10px;
    border-radius:500%;
    font-size:1.5em;
    position: absolute;
    bottom: 40px;
    left:calc(50% - 40px);
    z-index: 100;
}
button.close:hover{
    background-color: #154360;
}
.sub_data{
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    direction: ltr;
    justify-content: space-evenly;
    align-items: stretch;
}
.data_list{
   width: 300px;
   height: fit-content(20em);
   background-color: #FDFEFE;
   padding:15px 20px;
   border-radius: 5px;
   margin: 10px;
   box-shadow: 2px 2px 3px rgba(0,0,0,0.2),
   -2px -2px 3px rgba(0,0,0,0.2),
   -2px 1px 3px rgba(0,0,0,0.2),
   1px -2px 3px rgba(0,0,0,0.2);
   cursor: pointer;
}
.data_list:hover{
    background-color: #E8F8F5;
}
.data_list div:nth-child(3){
    margin-bottom: 10px;
}
.data_list div:nth-child(4){
    font-size: 0.8em;
}
.data_list div:nth-child(1){
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: lightgray;
    margin: auto;
    margin-bottom: 10px;
}
.data_list div span:nth-child(1){
    font-size: 0.9em;
    font-weight: 400;
}
.data_list div span:nth-child(2){
    font-size: 0.9em;
    font-weight: 300;
}
/* input form */
.form{
    background-color: #F8F9F9;
    width: calc(100% - 100px);
    height: calc(100vh - 100px);
    margin-top: 50px;
    margin-left: 50px;
    border-radius: 10px;
    position: relative;
}
.form_header{
    display: flex;
    flex-direction: row;
    background-color: #E5E8E8;
    padding: 20px 30px;
}
.form_header_left{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.form_header_left div{
    margin-left: 10px;
    margin-top: 3px;
}
.form_header_left div i{
    font-size: 0.9em;
    font-weight: 800;
    color: #555;
    margin-left: 10px;
}
.form_header_right{
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 30px;
}
.form_header_right div:nth-child(1){
    background-color: #036EB6;
    color: white;
    padding: 3px 6px;
    border-radius: 20px;
}
.form_header_right div:nth-child(2){
    margin-top: 3px;
}
.form_header_right div{
    margin-left: 10px;
}
.form_btn{
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom:10px;
    right: 10px;
}
.form_btn_left{
    margin-right: 10px;
}
.form_btn_left button{
    text-transform: capitalize;
    padding: 8px 25px;
    background-color: #E5E7E9;
    border:1px solid lightgray;
}
.form_btn_right button{
    text-transform: capitalize;
    padding: 8px 25px;
    color: white;
    background-color: #036EB6;
    border:1px solid lightgray;
}
.form_btn_right button:hover{
    background-color: #154360;
}
.form_progress{
    display: flex;
    flex-direction: row;
    background-color: transparent;
    height: 80px;
    justify-content: center;
    align-items: center;
    position: relative;
}
.form_progress_sub {
    background-color: transparent;
    display: inline-block;
}
.form_progress_sub div:nth-child(1) {
    background-color: lightgray;
    color: white;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    margin:0px 25px;
    transition: all 0.1s linear ;
}
.form_progress_items{
    display: inline-block;
    flex-direction: row;
    background-color:transparent ;
    position: relative;
    z-index: 1;
    width: 470px;
}
.form_progress_connect{
    width: 410px;
    background-color: lightgray;
    height: 10px;
    position:absolute;
    top: 15px;
    left: 25px;
    z-index: -1;
    border-radius: 5px;
}
.form_progress_connect_sub{
    position: absolute;
    top: 0px;
    left: 0px;
    /* width: var(--wid); */
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #036EB6;
    transition: width 0.1s linear;
}
.form_progress_sub div:nth-child(2) {
    font-size: 0.9em;
    color: gray;
    margin:0px 25px;
    margin-top: 10px;
    transition: color 0.1s linear;
}
.addProgress { 
    background-color: #036EB6;
    transition: all 0.1s linear ;
}
.addProgressc {
    color:#036EB6;
    transition: color 0.1s linear;
}
.form_progress button{
    text-transform: capitalize;
    padding: 8px 25px;
    color: white;
    background-color: #036EB6;
    border:1px solid lightgray;
}

.form_progress button:hover{
    background-color: #154360;
}

._switch{
    display: flex;
    flex-direction: row;
    padding:10px 10px;
}
._switch_label{
    margin-right: 10px;
}
._switch_btn{
    background-color: rgb(205, 207, 213);
    width: 60px;
    height: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
    border: 1px solid rgb(246, 246, 249);
    box-shadow: 2px 2px 2px rgba(0,0,0,0.2),
    -2px 2px 2px rgba(0,0,0,0.2),
    2px -2px 2px rgba(0,0,0,0.2),
    -2px -2px 2px rgba(0,0,0,0.2);
    cursor: pointer;
}

._switchon{

}
._switchoff{
 
}
._switch_btn_btn{
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgb(242, 240, 244);
    position: absolute;
    top:0.5px;
    left:1px;
    cursor: pointer;
    border: 2px solid rgb(251, 250, 250);
    animation: all 1s linear;
    transition: left 0.2s linear;
}
.addSwitch {
    background-color: green;
    color: white;
    transition: all 0.2s linear;
}
.addSwithbtn{
    left: 32px;
    transition: all 0.2s linear;
}
._increment_decrement_{
    opacity: 0;
    transition: opacity 0.5s linear;
}
.addIncDec{
    opacity: 1;
    transition: opacity 0.5s linear;
}
._increment_decrement_ button{
    background-color: #036EB6;
    color: white;
    cursor: pointer;
    width: 30px;
    height: 25px;
    padding-top: 5px;
    text-align: center;
    align-items: center;
    border: none;
    border-radius: 2px;
    outline: none;
    font-size: 1em;
    font-weight: 400;
}
._increment_decrement_ button:hover{
    background-color: #154360;
}
._increment_decrement_ span{
    margin-left: 10px;
    margin-right: 10px;
}
.success{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 500;
    background-color: rgba(0,0,0,0.6);
    width: calc(100%);
    height: calc(100vh -  100px - 0px);
    align-items: center;
    border-radius: 5px;
    display: none;
}
.success_sub{
    background-color: white;
    padding: 20px 100px;
    width: fit-content;
    align-self: center;
    text-align: center;
    border-radius: 5px;
    margin: auto;
    transform: translateY(200px);
}
.success_icon{
    color: #52BE80;
    font-size:9em;
}
.success_text{
    width: 200px;
    color: #52BE80;
    font-size: 2em;
}
.success_close{
    font-size: 1.5em;
    position: absolute;
    top:10px;
    right: 10px;
    cursor: pointer;
    font-weight: 600;
    color:gray;
}

/***** comman scroll bar **** */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.select{
    position: relative;
    margin:10px 0px;
}
.select_value {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding:10px 10px;
    cursor: pointer;
}
.addSelectValue{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.select_icon {
    display: flex;
    width: 26px;
    height: 26px;
    align-items: center;
    text-align: center;
    position: absolute;
    justify-content: center;
    right: 7px;
    top: 7px;
    transform: rotate(-90deg);
    transition: transform 0.3s linear;
    cursor: pointer;
}
.addSelectIcon{
    transform: rotate(90deg);
    transition: transform 0.3s linear;
}

.select_items {
    border: 1px solid lightgray;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    height: 0px;
    display: none;
    transition:display 0s linear 3s, height 0.3s linear;
    padding:0px 20px ;
    color: gray;
}
.addSelectItems {
    height: fit-content;
    display: block;
    transition:display 0s linear 3s, height 3s linear;
}
.userForm{
    padding: 10px;
}
.form_form {
    width: 520px;
    height: calc(100vh - 100px - 80px - 70px - 120px);
    /* background-color: #036EB6; */
    padding:10px;
    border-radius: 10px;
    /* border:1px solid gray; */
    margin: auto;
    overflow: auto;
    
}
.form_form::-webkit-scrollbar {
    width: 1px;
}
.label_b{
    font-size: 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.label_b div:nth-child(1){
    color:#99a7b1;
}
.label_b div:nth-child(2){
    color:#2980B9;
    margin:0px 10px ;
}
.opencloseform{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left:0px;
    z-index: 1000;
    margin: auto;
    width: calc(100%);
    height: inherit;
    background-color: rgba(0,0,0,0.6);
    display: none;
}
.opencloseform_sub{
    background-color: white;
    border-radius: 20px;
    width: 400px;
    /* height: calc(100vh - 100px - 200px); */
    display: flex;
    flex-direction: column;
    margin: auto;
    overflow: auto;
    justify-content: center;
    padding-bottom: 20px;
}
.opencloseform_close{
    display: flex;
    flex-direction: row;
    background-color: #036EB6;
    color: white;
    justify-content: center;
    position: relative;
    padding: 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.opencloseform_close div:nth-child(2){
    position: absolute;
    top:4px;
    right: 5px;
    z-index: 500px;
    /* background-color: red; */
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    font-weight: 700;
}
.opencloseform_close div:nth-child(2):hover{
    background-color: rgba(0,0,0,0.2);
}
.opencloseform div div{
    
}

.addOpenCloseForm {
    display: block;
}
.opencloseform_input{
    padding:20px;
}
input, textarea{
    margin: 5px;
    border: 1px solid lightgray;
    padding:10px;
    background-color: white;
    border-radius: 5px;
    font-size: 1.1em;
    width: 100%;
    margin:5px 10px;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    outline: none;
}
input:focus, textarea:focus{
    border:1px solid #5DADE2;
}

input::placeholder{
    color: lightgray;
}
input:checked{

}
input[type="checkbox"]{
    width: 20px;
    margin-right: 10px;
}
.label{
    font-size: 0.9em;
    color:gray;
    padding:5px 5px
}
.lable_btn{
    margin-bottom: 20px;
    font-size: 0.9em;
    cursor: pointer;
    color: #2980B9;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}
.data_displays{
    width: 498px;
    border: 1px solid lightgray;
    padding:20px;
    border-radius: 20px;
}
.data_lists{
    display: flex;
    flex-direction: row;
    margin: 15px;
    justify-content: space-between;
}
.data_label{
    width: 200px;
    font-weight: 600;
    color: rgb(107, 105, 105);
    font-size: 0.95em;
    /* background-color: #154360; */
}
.data_value{
    width: 240px;
    /* background-color: #036EB6; */
    font-size: 0.9em;
    color: gray;
}
.data_image {
    width: 460px;
    height: 171px;
    overflow: auto;
    /* background-color: #036EB6; */
    display: block;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 1px;
}
.data_image::-webkit-scrollbar {
    width: 1px;
    height: 5px;
}
.data_img{
    width: 200px;
    height: 150px;
    margin:0px 5px;
    border-radius: 5px;
    /* background-color: wheat; */
    display: inline-block; 
}
.data_img img{
    width: inherit;
    height: inherit;
    border-radius: 5px;
}

@media screen and (max-width:1024px) {
    .form_form {
        width: 520px;
    }
}
@media screen and (max-width:800px) {
    .form_form {
        width: 520px;
    }
}
@media screen and (max-width:720px) {
    .form_form {
        width: 520px;
    }
}
@media screen and (max-width:540px) {
    .form_form {
        width: 520px;
        overflow: auto;
    }
}
